<template>
  <div class="cart-item">
    <div v-if="item.funds_1 || item.funds_2 || false" class="sale">
      {{ $t("sale")
      }}<span class="uslovieSale"
    >{{ $t("uslovieSale") }}
        <div class="dropsSale" v-if="item.funds_1">
          {{ item.funds_1_description.name }}
        </div>
        <div class="dropsSale" v-else-if="item.funds_2">
          {{ item.funds_2_description.name }}
        </div>
      </span>
    </div>
    <div
        class="cart-item__group"
        :class="{ padBlock: item.funds_1 || item.funds_2 || false }"
    >
      <div class="cart-item__image">
        <img :src="cdn + item.main_image" alt="" />
      </div>
      <div class="cart-item__info">
        <div class="cart-item__name" v-if="title">{{ item.title }}</div>
<!--        <div class="cart-item__name" v-else>{{ item.title }}</div>-->
        <div class="cart-item__code">
          {{ $t("articule") }} <span>{{ item.artikul }}</span>
        </div>
        <div class="cart-item__price">{{ formatPrice }}</div>
      </div>
    </div>
    <div
        style="
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        gap: 20px;
      "
    >
      <div class="radioButtons">
        <div class="radioBlock">
          <input type="radio" @click="this.item.quantity = 1" v-model="typeBuy" :value="1" />
          <label style="white-space: nowrap">{{ $t("inPieces") }}</label>
        </div>
        <div class="radioBlock">
          <input type="radio" @click="this.item.quantity = 1" v-model="typeBuy" :value="0" />
          <label>{{
              $t("box_count", { value: item.features?.quantity || 0 })
            }}</label>
        </div>
      </div>
      <div class="cart-item__additional">
        <div class="cart-item__counter">
          <button class="cart-item__btn" @click="removeCartProduct">
            <span>-</span>
          </button>
          <input
              @input="noZero"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              :style="
              'width:' +
              parseInt(
                parseInt(String(item.quantity).length) * parseInt(10) +
                  parseInt(20)
              ) +
              'px;'
            "
              class="inputCount"
              v-model="item.quantity"
          />
          <button :disabled="typeBuy === 1 && item.total_count <= item.quantity ? true : typeBuy === 0 && item.box_count <= item.quantity ? true : false" class="cart-item__btn" @click="addCartProduct">
            <span>+</span>
          </button>
        </div>
        <div class="cart-item__actions">
          <button
              class="cart-item__icon"
              ref="favoriteBtn"
              @click="addFavoriteProduct"
              :class="{ active: favorite || item.favourite }"
          >
            <span>
              <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_514_7172)">
                  <path
                      d="M2.04673 11.0474L9.99935 19L17.952 11.0474C18.836 10.1633 19.3327 8.96429 19.3327 7.71404C19.3327 5.11055 17.2221 3 14.6186 3C13.3684 3 12.1694 3.49666 11.2853 4.38071L9.99935 5.66667L8.71339 4.38071C7.82934 3.49666 6.6303 3 5.38006 3C2.77657 3 0.666016 5.11055 0.666016 7.71404C0.666016 8.96429 1.16267 10.1633 2.04673 11.0474Z"
                      fill="none"
                  />
                  <path
                      d="M14.5835 1.59711C13.6451 1.6117 12.7272 1.87365 11.9224 2.3565C11.1177 2.83935 10.4546 3.526 10.0001 4.34711C9.54566 3.526 8.88257 2.83935 8.07783 2.3565C7.27308 1.87365 6.35517 1.6117 5.41679 1.59711C3.92091 1.6621 2.51155 2.31649 1.49661 3.4173C0.481678 4.51812 -0.0563308 5.97588 0.000128003 7.47211C0.000128003 11.2613 3.98846 15.3996 7.33346 18.2054C8.08031 18.833 9.02459 19.1771 10.0001 19.1771C10.9757 19.1771 11.9199 18.833 12.6668 18.2054C16.0118 15.3996 20.0001 11.2613 20.0001 7.47211C20.0566 5.97588 19.5186 4.51812 18.5036 3.4173C17.4887 2.31649 16.0793 1.6621 14.5835 1.59711ZM11.596 16.9304C11.1493 17.3066 10.5841 17.5129 10.0001 17.5129C9.41617 17.5129 8.85097 17.3066 8.40429 16.9304C4.12263 13.3379 1.66679 9.89127 1.66679 7.47211C1.60983 6.4177 1.9721 5.38355 2.6746 4.59519C3.37709 3.80683 4.36282 3.32823 5.41679 3.26377C6.47077 3.32823 7.45649 3.80683 8.15899 4.59519C8.86149 5.38355 9.22376 6.4177 9.16679 7.47211C9.16679 7.69312 9.25459 7.90508 9.41087 8.06136C9.56715 8.21764 9.77911 8.30544 10.0001 8.30544C10.2211 8.30544 10.4331 8.21764 10.5894 8.06136C10.7457 7.90508 10.8335 7.69312 10.8335 7.47211C10.7765 6.4177 11.1388 5.38355 11.8413 4.59519C12.5438 3.80683 13.5295 3.32823 14.5835 3.26377C15.6374 3.32823 16.6232 3.80683 17.3257 4.59519C18.0282 5.38355 18.3904 6.4177 18.3335 7.47211C18.3335 9.89127 15.8776 13.3379 11.596 16.9271V16.9304Z"
                      fill="#727272"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_514_7172">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </button>
          <div class="cart-item__action" @click="deleteProductFromCart">
            <svg
                width="14"
                height="18"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M4.2 3.89998V2.19998C4.2 1.57406 4.61787 1.06665 5.13333 1.06665H8.86667C9.38213 1.06665 9.8 1.57406 9.8 2.19998V3.89998M0 4.46665H14M1.4 4.46665V15.8C1.4 16.4259 1.81787 16.9333 2.33333 16.9333H11.6667C12.1821 16.9333 12.6 16.4259 12.6 15.8V4.46665M7 8.43332V14.1M4.2 10.7V14.1M9.8 10.7V14.1"
                  stroke="#77757F"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="leftFunds" v-if="item.funds1">
        {{ item.funds1.name
        }}<template v-if="actCounter > 0">: {{ actCounter }} шт</template>
      </div>
      <div class="leftFunds" v-if="item.funds2">
        {{ item.funds2.name
        }}<template v-if="bonusBool"
      >:
        {{
          this.item.typeBuy == 0
              ? parseInt(
              (this.item.quantity *
                  parseInt(this.item.features.quantity) *
                  this.item.current_price) /
              this.item.funds2.min_count
          ) * parseInt(this.item.funds2.bonus)
              : parseInt(
              (this.item.quantity * this.item.current_price) /
              this.item.funds2.min_count
          ) * parseInt(this.item.funds2.bonus)
        }}
        тг</template
      >
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: {
    item: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      counter: 0,
      actCounter: 0,
      bonusBool: false,
      typeBuy: null,
      totalItem: 0
    };
  },
  computed: {
    ...mapState(["cdn", "auth", "favorites"]),
    formatPrice() {
      let logs = this.item.typeBuy == 0
          ?
          (this.item.quantity *
              parseInt(this.item.features.quantity) *
              this.item.current_price)
          : parseInt(
              (this.item.quantity * this.item.current_price)
          )
      return (
          logs.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₸"
      );
    },
    favorite() {
      return this.favorites.find((item) => parseInt(item.id) === this.item.id);
    },
  },
  methods: {
    ...mapMutations([
      "removeCart",
      "addFavoriteToLocal",
      "addToCart",
      "deleteProduct",
    ]),
    addCartProduct() {
      if (!this.item.quantity) {
        this.item.quantity = 1;
      }
      this.addToCart({ ...this.item, typeBuy: this.typeBuy });
    },
    addFavoriteProduct() {
      if (this.auth) {
        let productId = this.isFavorites
            ? this.item.id
            : this.item.user_favourite_id;
        let favoriteProduct = this.favorites.find(
            (product) => parseInt(product.id) === productId
        );
        if (favoriteProduct) {
          this.removeFavorite(favoriteProduct.id);
        } else {
          this.addFavorite({ product_id: this.item.id });
        }
        this.fetchFavorites();
        this.$emit("change");
      } else {
        this.addFavoriteToLocal(this.item);
      }
    },
    removeCartProduct() {
      this.removeCart(this.item);
    },
    deleteProductFromCart() {
      this.$emit("delete", {
        id: this.item.id,
      });
      this.deleteProduct(this.item);
    },
    noZero(e) {
      if (this.typeBuy === 1) {
        if (e.target.value > this.item.total_count) {
          this.item.quantity = this.item.total_count
        }
        // this.countBy = this.countBy1 * this.count
      } else {
        if (e.target.value > this.item.box_count) {
          this.item.quantity = this.item.box_count
        }
        // this.countBy = this.product.features.quantity * this.countBy1 * this.count
      }
      e.target.value = e.target.value.replace(/^0/, "");
    },
  },
  beforeMount() {},
  async mounted() {
    this.typeBuy = parseInt(this.item.typeBuy);
    if (
        (await this.item.funds2) &&
        this.item.quantity *
        parseInt(this.item.features.quantity) *
        this.item.current_price >
        this.item.funds2.min_count
    ) {
      this.$emit("boolBonus", {
        id: this.item.id,
        bonus:
            parseInt(
                (this.item.quantity *
                    parseInt(this.item.features.quantity) *
                    this.item.current_price) /
                this.item.funds2.min_count
            ) * parseInt(this.item.funds2.bonus),
        count: this.item.quantity,
        typeBuy: this.typeBuy,
        bool: 1,
      });
      this.bonusBool = true;
    } else if (await this.item.funds2) {
      this.$emit("boolBonus", {
        id: this.item.id,
        count: this.item.quantity,
        bonus: this.item.funds2.bonus,
        typeBuy: this.typeBuy,
        bool: 0,
      });
    }
    if (await this.item.funds1) {
      this.counter = 0;
      this.$emit("counter", {
        id: this.item.id,
        count: this.actCounter,
        quant: this.item.quantity,
        price: this.item.current_price,
        funds_1: 1,
        typeBuy: this.typeBuy,
        total: this.item.quantity + this.actCounter,
      });
    }

    if (this.item.funds1) {
      this.actCounter =
          this.item.typeBuy == 0
              ? Math.floor(
                  (parseInt(this.item.quantity) *
                      parseInt(this.item.features.quantity)) /
                  parseInt(this.item.funds1.min_count)
              )
              : Math.floor(
                  parseInt(this.item.quantity) /
                  parseInt(this.item.funds1.min_count)
              );
    }
    this.$emit("typeBuy", this.typeBuy);
  },
  watch: {
    typeBuy() {
      this.$emit("typeBuy", this.typeBuy);
      this.addToCart({
        ...this.item,
        quantity: this.item.quantity <= 1 ? 0 : this.item.quantity,
        typeBuy: this.typeBuy,
      });
      if (this.item.funds1) {
        this.actCounter =
            this.item.typeBuy == 0
                ? Math.floor(
                    (parseInt(this.item.quantity) *
                        parseInt(this.item.features.quantity)) /
                    parseInt(this.item.funds1.min_count)
                )
                : Math.floor(
                    parseInt(this.item.quantity) /
                    parseInt(this.item.funds1.min_count)
                );
      }
    },
    "item.quantity"() {
      if (this.item.funds2) {
        this.item.quantity *
        parseInt(this.item.features.quantity) *
        this.item.current_price >=
        this.item.funds2.min_count
            ? (this.bonusBool = true)
            : (this.bonusBool = false);
        this.bonusBool === true
            ? this.$emit("boolBonus", {
              id: this.item.id,
              count: this.item.quantity,
              bonus:
                  this.item.typeBuy == 0
                      ? parseInt(
                      (this.item.quantity *
                          parseInt(this.item.features.quantity) *
                          this.item.current_price) /
                      this.item.funds2.min_count
                  ) * parseInt(this.item.funds2.bonus)
                      : parseInt(
                      (this.item.quantity * this.item.current_price) /
                      this.item.funds2.min_count
                  ) * parseInt(this.item.funds2.bonus),
              bool: 1,
              typeBuy: this.typeBuy,
            })
            : this.$emit("boolBonus", {
              id: this.item.id,
              count: this.item.quantity,
              bonus: this.item.funds2.bonus,
              bool: 0,
              typeBuy: this.typeBuy,
            });
      }
      if (this.item.funds1) {
        this.item.typeBuy == 0
            ? (this.counter = Math.floor(
                (parseInt(this.item.quantity) *
                    parseInt(this.item.features.quantity)) /
                parseInt(this.item.funds1.min_count)
            ))
            : (this.counter = Math.floor(
                parseInt(this.item.quantity) /
                parseInt(this.item.funds1.min_count)
            ));
        this.$emit("counter", {
          id: this.item.id,
          count: this.actCounter,
          quant: this.item.quantity,
          price: this.item.current_price,
          funds_1: 1,
          typeBuy: this.typeBuy,
          total: this.item.quantity + this.actCounter,
        });
      }
    },
    counter() {
      this.actCounter =
          this.item.typeBuy == 0
              ? Math.floor(
                  (parseInt(this.item.quantity) *
                      parseInt(this.item.features.quantity)) /
                  parseInt(this.item.funds1.min_count)
              )
              : Math.floor(
                  parseInt(this.item.quantity) /
                  parseInt(this.item.funds1.min_count)
              );
      this.$emit("counter", {
        id: this.item.id,
        count: this.actCounter,
        quant: this.item.quantity,
        price: this.item.current_price,
        typeBuy: this.typeBuy,
        funds_1: 1,
        total: this.item.quantity + this.actCounter,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
button:disabled,
button[disabled]{
  background-color: #e3dfde;
  height: 100%;
}
.radioButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  font-size: 12px;
  min-width: 215px;
  .radioBlock {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  @media (max-width: $tablet + px) {
    margin-bottom: 0px !important;
    width: 100% !important;
    justify-content: flex-start;
  }
}

.leftFunds {
  @media (max-width: $tablet + px) {
    width: 100% !important;
  }
}
.cart-item {
  position: relative;
  border: 1px solid #c2c2c2;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 22px 15px;
  overflow: hidden;
  @media (min-width: $tablet + px) {
    padding: 22px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 30px;
  }
  @media (min-width: $mobile + px) {
    min-width: 300px;
  }
  &__icon {
    transition: all 0.3s ease 0s;

    svg {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      transition: all 0.5s ease 0s;

      &:hover {
        path {
          fill: #1ABBE8;
        }
      }
    }

    &.active {
      animation: heartPulse 0.5s ease both;
      svg {
        path {
          fill: #1ABBE8;
        }
      }
    }

    @keyframes heartPulse {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1.3);
      }
    }

    @media (max-width: $mobileSmall + px) {
      img {
        width: 19.22px;
        height: 17.58px;
      }
      top: 15px;
      right: 14.41px;
    }
  }
  &__group {
    display: flex;
    position: relative;
    align-items: center;
    gap: 0 17px;
    @media (max-width: $tablet + px) {
      gap: 0 8px;
      margin: 0 0 30px 0;
    }
  }
  &__actions,
  &__additional {
    display: flex;
    align-items: center;
    gap: 0 35px;
    @media (max-width: $tablet + px) {
      gap: 0 20px;
      padding-left: 40px;
    }
  }
  &__additional {
    @media (max-width: $tablet + px) {
      justify-content: space-between;
      width: 100%;
    }
  }
  &__image {
    flex: 0 0 72px;
    height: 72px;
    img {
      height: 100%;
      object-fit: contain;
      max-height: 100%;
      max-width: 100%;
    }
  }
  &__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin: 0 0 14px 0;
  }
  &__code {
    font-weight: 400;
    font-size: 12px;
    line-height: 117%;
    color: #555555;
    margin: 0 0 14px 0;
    span {
      white-space: nowrap;
      font-weight: 600;
    }
  }
  &__price {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
  &__counter {
    height: 30px;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
  }
  &__btn {
    @extend .base-text;
    cursor: pointer;
    font-weight: 400;
    color: #77757f;
    padding: 0 8px;
  }
  &__quantity {
    height: 100%;
    padding: 0 10px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    border-left: 1px solid #cfcfcf;
    border-right: 1px solid #cfcfcf;
  }
  &__action {
    svg {
      width: 14px;
      height: 17px;
      cursor: pointer;
    }
  }
}

.sale {
  position: absolute;
  top: 10px;
  left: -5px;
  margin-bottom: 20px;
  min-width: 78px;
  height: 24px;
  background: url("../assets/images/sale.png");
  background-repeat: no-repeat;
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
  padding-left: 10px;
  padding-top: 2px;
  text-align: center;
}

.uslovieSale {
  font-weight: 500;
  font-size: 12px;
  text-decoration-line: underline;
  color: #1ABBE8;
  cursor: pointer;
  position: relative;
  margin-left: 30px;
  &:hover {
    .dropsSale {
      display: block;
    }
  }
}

.dropsSale {
  display: none;
  position: absolute;
  top: -80%;
  z-index: 100;
  margin-left: 15px;
  left: 100%;
  background-color: #efefef;
  border-radius: 20px;
  font-weight: 500;
  width: 235px;
  height: 74px;
  padding-right: 13px;
  padding-top: 15px;
  padding-bottom: 20px;
  text-align: start;
  padding-left: 15px;
  font-size: 14px;
  line-height: 17px;
  color: #202020;
  &:after,
  &:before {
    right: 98%;
    top: 30%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #efefef;
    border-width: 15px;
    margin-top: -15px;
  }
  @media screen and (max-width: 762px) {
    top: 160%;
    margin-left: 0px;
    max-width: 200px;
    padding-right: 20px;
    left: 0%;
    &:after,
    &:before {
      top: -12%;
      left: 15%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #efefef;
      border-width: 10px;
      margin-top: -10px;
    }
  }
}
.form-control:focus-within {
  color: var(--form-control-color);
}

.form-control + .form-control {
  margin-top: 1em;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #1ABBE8;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border: 0.15em solid #1ABBE8;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  /* Windows High Contrast Mode */
  background-color: #1ABBE8;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
.padBlock {
  padding-top: 20px;
}
</style>